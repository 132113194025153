body,
div,
ul {
  padding: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

/* ========================================
 footer style
======================================== */
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer__copy {
  padding: 12px 0 10px;
  text-align: center;
  border-top: 4px solid #f68699;
}

.footer__copy small {
  color: #f68699;
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', Verdana, Roboto, 'Droid Sans', '游ゴシック',
    YuGothic, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 14px;
  line-height: 1.8;
  color: #000031;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.lnln-container {
  background-color: #fff;
  padding: 40px 15px 0px;
  font-size: clamp(0.5rem, 2vh, 2rem);
}

ol,
ul {
  list-style: none;
}

.list li {
  margin-top: 5px;
  padding-left: 15px;
  position: relative;
  line-height: 1.6;
}

.list li::before {
  content: "・";
  position: absolute;
  left: 0;
}

.t-a-c {
  text-align: center;
}

.m-t-40 {
  margin-top: 30px;
}

/* ========================================
 vodeo style
======================================== */
.endCallContainer,
.loadingVideoContainer {
  text-align: center;
  font-size: 20px;
}

.endCallChild,
.loadingVideoChild {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.videoGallery {
  width: 100vw;
}

.sendMessageDialog {
  width: 100%;
  height: 100%;
  padding: 7px 20px 15px 20px;
  text-align: center;

  .title {
    margin-top: 10px;
    font-family: Hiragino Kaku Gothic Pro;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    text-align: left;
    color: rgba(0, 0, 49, 1);
  }
}

.button {
  width: 300px;
  height: 46px;
  border: 0px;
  border-radius: 100px;
  font-family: Hiragino Kaku Gothic Pro;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: center;
  padding: 12px 20px;
  color: rgb(255, 255, 255, 1);
  background: rgb(246, 134, 153, 1);
}

.sendMessageTipDialog {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000001;

  .dialog-content {
    padding: 30px 20px 30px 20px;
    text-align: center;
    position: absolute;
    background: rgb(255, 255, 255, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    .title {
      margin-bottom: 20px;
      font-family: Hiragino Kaku Gothic Pro;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: rgba(0, 0, 49, 1);
    }
  
    .message {
      margin-bottom: 20px;
      font-family: Hiragino Kaku Gothic Pro;
      font-size: 15px;
      font-weight: 300;
      line-height: 25px;
      text-align: left;
      color: rgba(0, 0, 49, 1);
    }
  
    .messagePs {
      margin-bottom: 20px;
      font-family: Hiragino Kaku Gothic Pro;
      font-size: 12px;
      font-weight: 300;
      line-height: 19px;
      text-align: left;
      color: rgba(157, 157, 162, 1);
    }
  }
}

/* ビデオウィンドウのドラッグ警告を解決するCSS */
* {
  touch-action: pan-y;
}